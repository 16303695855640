@import "../settings/variables";
@import "../settings/mixins";
@import "../settings/responsive";
@import "~cropperjs/src/index.scss";

.image-editor-modal{
    .editable-image{ 
        max-height: calc(100vh - 288px);
        max-width: 100%;
    }
    .image-editor-controls{
        @include flex(both, center-center);
        margin: 16px 0;

        button{
            width: 40px;
            margin: 0 4px;
            padding: 4px;

            &:before{ color: $primary-blue; }
        }
    }
    
    /* .container needs to be same size at img */
    .cropper-container {
        width: calc(100vw - 80px)!important;
        height:calc(100vw - 80px);
        max-width: 300px!important;
        max-height: 300px!important;
        background:rgba(0, 0, 0, 0.5);
        margin:0 auto;
        position:relative;
        z-index:1;
        overflow: hidden;
    }
    /* .cropper-container:before 1/4 img width, 1/2 img height, left 0, top 1/4 img, */
    .cropper-container:before {
        content:" ";
        display:block;
        width:50px;
        height:calc(100vw - 180px);
        max-height: 200px!important;
        position:absolute;
        left:0;
        top:50px;
        z-index:15;
        background:rgba(0, 0, 0, 0.5);
        opacity:0.5;
    }
    /* .cropper-container:before 1/4 img width, 1/2 img height, left 3/4 img, top 1/4 img */
    .cropper-container:after {
        content:" ";
        display:block;
        width:50px;
        height:calc(100vw - 180px);
        max-height: 200px!important;
        position:absolute;
        right:0;
        top:50px;
        z-index:15;
        background:rgba(0, 0, 0, 0.5);
        opacity:0.5;
    }
    .reactEasyCrop_Container{
        img {
            position: relative;
            margin: 0;
        }
        .reactEasyCrop_CropArea {
            width: calc(100vw - 180px)!important;
            height: calc(100vw - 180px)!important;
            max-width: 200px!important;
            max-height: 200px!important;
            transform: none!important;
            top: 50px!important;
            left: 50px!important;
        }
        width: calc(100vw - 80px)!important;
        height: calc(100vw - 80px)!important;
        max-width: 300px!important;
        max-height: 300px!important;
        display: flex;
        position: relative;
        text-align: center;
    }
    /* .img would be your actual img at full size */
    .bg {
        display:block;
        width:calc(100vw - 80px);
        height:calc(100vw - 80px);
        max-width: 300px;
        max-height: 300px;
        position:absolute;
        z-index:10;
        background: none;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        &.width {
            .reactEasyCrop_Container {
                img {
                    height: auto;
                    width: calc(100vw - 180px)!important;
                    max-width: 200px!important;
                }
            }
        }
        &.height {
            .reactEasyCrop_Container {
                img {
                    width: auto;
                    height: calc(100vw - 180px)!important;
                    max-height: 200px!important;
                }
            }
        }
    }
    /* .img:before same width as img, 1/4 height, top 0, left 0 */
    .bg:before   {
        content:"";
        display:block;
        width:100%;
        height:50px;
        position:absolute;
        left:0;
        top:0;
        z-index:15;
        background:rgba(0, 0, 0, 0.5);
        opacity:0.5;
    }
    /* .img:after same width as img, 1/4 height, top 3/4 img height, left 0 */
    .bg:after {
        content:"";
        display:block;
        width:100%;
        height:50px;
        position:absolute;
        left:0;
        bottom:0;
        z-index:15;
        background:rgba(0, 0, 0, 0.5);
        opacity:0.5;
    }
}