@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('./open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('./open-sans-v17-latin-regular.woff2') format('woff2'), /* Modern Browsers */
        url('./open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}
