@import '../settings/responsive';

.stepper{
    margin-bottom: 32px;

    @include breakpointByScreenType(tablet){ @include flex(justify, space-between); }
    div{
        width: 100%;
        text-align: center;
        padding : 0 16px 8px;
        border-bottom: 4px solid $primary-blue;
        box-sizing: border-box;
        color: $primary-lightgrey;

        @include breakpointByScreenType(mobile){
            font-size: $font-M;
            line-height: $font-L;
            display: none;
        }
        @include breakpointByScreenType(tablet){
            font-size: $font-S;
            line-height: $font-M;
            flex: 1;
        }
        &.active{
            color: $primary-black;
            // font-weight: $font-weight-bold;

            @include breakpointByScreenType(mobile){ display: block; }
            @include breakpointByScreenType(tablet){
                &~div{ border-bottom: 4px solid $primary-lightgrey; }
            }
        }
    }
}