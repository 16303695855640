.forgot-password {
    .action-box {
        .partners {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: 32px;
            overflow: hidden;

            a {
                margin: 0 8px 0;
            }
            .avatar, .avatar:after, img {
                overflow: hidden;
                width: 32px;
                height: 32px;
            }
        }
        .divider {
            margin: 2rem 0;
        }
        .logo {
            margin: 0.5rem auto;
        }
        h3 {
            margin-bottom: 1.75rem;
        }
    }
}