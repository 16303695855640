@import 'settings/variables';
.snackbar {
    z-index: 1;
    position: sticky;
    left: -32px;
    transition-property: margin-top, top;
    transition-duration: 1s;
    background-color: #f4cf8d;
    text-align: center;
    padding: 16px;
    color: $primary-black;
    margin-top: -56.31px;
    @media(max-width: 755px) {
        margin-top: -80px;
    }
    @media(max-width: 408px) {
        margin-top: -100px;
    }
    @media(max-width: 286px) {
        margin-top: -120px;
    }
    @media(max-width: 230px) {
        margin-top: -150px;
    }
    &.show {
        margin-top: 0;
    }
}