// Font Awesome
@import "~font-awesome/scss/font-awesome.scss";

// Settings
@import "./settings/variables";
@import "./settings/mixins";
@import "./settings/responsive";
@import "./settings/animation";

// Libs
@import "./libs/reset";

// Base
@import "./base/fonts";
@import "./base/main";
@import "./base/button";
@import "./base/input";
@import "./base/alert";
@import "./base/action-box";
@import "./base/form";
@import "./base/modal";
@import "./base/icon";
@import "./base/switch";

// Modules
@import "./modules/header";
@import "./modules/footer";
@import "./modules/faqs";

body {
    overflow: hidden;
}

#root {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    .classname::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
    }
    & > div {
        height: 100%
    }
}