@import '../settings/variables';

.dataTables_wrapper{ margin: 40px 0; }
.dataTables_filter{
    @include flex (both, center-center);
    flex-wrap: wrap;
    margin-bottom: 40px;

    @include breakpointBySize(0, $break-tablet-max){ flex-direction: column; }
    span{ 
        padding: 0 16px; 
        font-size: $font-XS;
        color: $primary-black;
        // font-weight: 600;

        @include breakpointBySize(0, $break-tablet-max){ margin: 16px 0; }
    }
    input{
        width: 100%;
        border-radius: 20px;
        background-color: inherit;
    }
    .date-filter-1{
        @include flex (both, center-center);

        @include breakpointBySize(0, $break-tablet-max){ width: 100%; }
        button{
            margin: 0 8px;
            color: $primary-grey;
            border: $border;
            font-size: $font-XS;
            padding: 8px 12px;
            border-radius: 20px;
            box-shadow: $input-shadow;
            letter-spacing: 1px;
            white-space: nowrap;

            @include breakpointByScreenType(mobile){ flex: 1; }
            &:first-child{ margin-left: 0; }
            &:last-child{ margin-right: 0; }
            &.active, &:hover{
                background: $primary-blue;
                border-color: $primary-blue;
                color: $primary-purewhite;
                box-shadow: 0;
            }
        } 
    }
    .date-filter-2{
        @include flex (both, center-center);

        @include breakpointBySize(0, $break-tablet-max){ width: 100%; }
        &>section{
            margin: 0 8px;
            
            @include breakpointByScreenType(mobile){ flex: 1; }
            @include breakpointByScreenType(tablet){
                min-width: 120px;
                max-width: 127px;
            }
            &:before{
                position: absolute;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
            }
            &:first-child{ 
                margin-left: 0; 

                &:before{ background: url('../images/date-from.svg'); }
            }
            &:last-child{ 
                margin-right: 0; 
                
                &:before{ background: url('../images/date-to.svg'); }
            }
            input{ 
                padding: 3px 30px 3px 10px;
                font-size: $font-XS;
                max-height: 35px;
            }
        }
    }
    label{
        @include breakpointBySize(0, $break-tablet-max){ width: 100%; }
        @include breakpointByScreenType(tablet){ max-width: 360px; }
        input{ 
            padding: 3px 10px 3px 10px; 
            font-size: $font-XS;
            max-height: 35px;
            min-width: 260px;
        }
    }
    .search-icon {
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin: 0.75rem;
        background-image: url('../images/search.svg');
    }
}
.dataTables_scrollBody {
    overflow-x: auto;
}
.dataTable{
    width: 100%;

    th,td{ 
        box-sizing: border-box;
        padding: 16px 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
        
        &.text{ max-width: 240px;}
    }
    thead{
        font-size: $font-S;
        color: $primary-black;
        text-align: left;
        
        th, td {
            border-bottom: 1px solid $primary-lightgrey;

            &:active { outline: none; }
        }
        .check-box label{
            padding: 0;
            width: 12px;
            height: 12px;
        }
        .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
            cursor: pointer;
            *cursor: hand;

            &:after{
                display: inline-block;
                vertical-align: middle;
                content: '';
                width: 12px;
                height: 12px;
                margin-left: 8px;
            }
            &:hover{ background: #f9f9f9; }
        }
        .sorting:after { background: url('../images/sort_default.svg') }
        .sorting_asc:after { background: url('../images/sort_asc.svg') }
        .sorting_desc:after { background: url('../images/sort_desc.svg') }
    }
    thead, tbody{
        font-size: $font-XS;
        color: $primary-grey;

        tr{
            border-bottom: 1px solid #f9f9f9;

            &.odd{ background: #f9f9f9; }
            .check-box:after{
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                border: 1px solid $primary-lightgrey;
                background: $primary-purewhite;
                border-radius: 2px;
                box-shadow: $input-shadow;
            }
            &.selected .check-box:after{
                background: url('../images/check.svg') no-repeat;
                background-size: 10px 10px;
                background-position: center;
                background-color: $primary-blue;
            }
        }
        .bold { font-weight: $font-weight-bold; }
        .blue{ color: $primary-blue; }
        .yellow{ color: $secondary-yellow; }
        .green{ color: $primary-green; }
        .red{ color: $secondary-red; }
        .cell-left{ text-align: left; }
        .cell-center{ text-align: center; }
        .cell-right{ text-align: right; }
        .dataTables_empty{
            text-align: center;
            
            .spinner-container{ display: inline-block; }
            .spinner{
                height: 72px;
                width: 72px;
                border: 4px solid $primary-blue;
                border-right-color: transparent;
                border-top-color: transparent;
                border-radius: 100%;
                animation: spin 1500ms infinite linear;
            }
        }
    }
}
.dataTables_scroll {
    clear: both;

    div.dataTables_scrollBody {
        *margin-top: -1px;
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
        > table > thead > tr{
            > th{ 
                vertical-align: middle; 

                &:after{ content: none; }
            }
            .dataTables_sizing {
                height: 0;
                overflow: hidden;
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }
}
.dataTables_paginate{
    margin-top: 40px;
    @include flex(both, center-center);
    text-align: center;

    .previous, .next{ padding: 2px; }
    .previous{ margin-right: 12px; }
    .next{ margin-left: 12px; }
    .previous:before, .next:before{
        content: '';
        display: block;
        background-size: contain;
        width: 16px;
        height: 16px;
    }
    .previous:before{ background: url('../images/arrow_left.svg'); }
    .next:before{ background: url('../images/arrow_right.svg'); }
    span .paginate_button {
        display: inline-block;
        min-width: 12px;
        padding: 2px;
        margin-left: 12px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;
        color: $primary-lightgrey !important;

        &:first-child{ margin-left: 0; }
        &.current, &.current:hover{
            color: $primary-blue !important;
            border-bottom: 1px solid $primary-blue;
        }
        &.disabled, &.disabled:hover, &.disabled:active{ cursor: default; }
        &:hover{ color: $primary-blue !important; }
        &:active {
            color: $primary-blue !important;
            border-bottom: 1px solid $primary-blue;
        }
    }
    .ellipsis{ padding: 2px;}
}