.action-box-wrapper {
    position: relative;
    box-sizing: border-box;
    min-height: calc(100vh - 56px);
    width: 100%;
    padding: 32px 16px;
    @include flex(both, center-center);
}
.action-box {
    width: 100%;
    text-align: center;

    @include breakpointByScreenType(tablet) {
        max-width: 512px;
    }

    h1{
        font-size: 36px;
        margin-bottom: 0.5rem;
    }
    .logo {
        margin: 1rem auto;
        display: block;
        width: 48px;
        height: 48px;
    }
    .divider {
        margin: 1rem 0;
    }
    &.content-box {
        background-color: $primary-purewhite;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 8px;
        // border: $border;
        
        @include breakpointByScreenType(tablet) {
            padding: 40px 60px;
        }
    }
    form, .pseudo-form {
        small:empty {
            min-height: 0!important;
            line-height: 0!important;
        }
        h3 {
            margin-bottom: 16px;
        }
        & > p {
            width: 100%;
            margin-bottom: 40px;
            &.twofa-timer{
                margin: 16px 0;
            }
        }
        .footer {
            margin-top: 0;
            padding-bottom: 0;

            & > button, & > a > button {
                width: 100% !important;
            }
            p{
                margin: 0 auto 0;
            }
        }
        .input-box{
            height: 40px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            .input-prepend {
                position: relative;
                margin: auto;
                width: 100%;
            }
            input{
                margin: 0;
            }

            &:last-of-type{
                margin-bottom: 0;
            }
            &:only-of-type{
                margin: 0;
            }
        }
        .error-container{
            display: block !important;
            height: fit-content;
            margin: 14px 0;
        }
    }
    &.success {
        img {
            width: 72px;
            height: 72px;
        }
        p {
            width: 80%;
            margin: auto;
            margin-bottom: 40px;

            &:last-child{
                margin-bottom: 0;
            }
        }
        .link{
            margin: 40px auto 0;
        }
    }
    &.account-alert {
        min-width: 500px;
        max-width: 1000px!important;
        h2 {
            line-height: 2rem;
        }
        img {
            width: 100px;
            height: 100px;
        }
        .body {
            margin: 2.5rem 0;
            p {
                word-spacing: -0.05em;
            }
        }
        .input-box {
            display: block;
        }
    }
    &.error {
        img {
            margin-bottom: 24px;
            width: 120px;
        }
        p {
            width: 80%;
            margin: auto;
            margin-bottom: 24px;
        }
        button,
        a {
            max-width: 280px;
        }
    }
    &.not_found {
        p {
            font-size: 16px !important;
            letter-spacing: 1px !important;
        }
    }
    .share-links {
        @include breakpointByScreenType(tablet) {
            @include flex(justify, space-between);
            a {
                flex: 0 0 128px;
            }
        }
        @include breakpointByScreenType(mobile) {
            margin-top: 24px;
            width: 128px;
            margin: auto;
        }
    }
    .social-links{
        @include flex(justify, center);
        a{
            margin: 0 12px;
        }
        .button.social{ 
            min-width: 40px;
            min-height: 40px;
            padding: 8px;
            border-radius: 100%;
            margin-top: 0;
            
            &:before{
                font-size: 16px;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    button,
    a {
        @include breakpointByScreenType(tablet) {
            margin-right: 8px;
            margin-left: 8px;

            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
        @include breakpointByScreenType(mobile) {
            // margin-top: 24px;
        }
        text-decoration: none;
    }
    @include breakpointByScreenType(mobile) {
        a:first-of-type button {
            margin-top: 0;
        }
    }
    &.success-signup {
        font-family: Arial;
        color      : $primary-black;
        font-size  : 14px;
        width      : 350px;
        padding    : 2.5rem 0.75rem 1rem;
        @media(min-width: 360px) {
            max-width  : 350px;
            min-width  : 350px;
        }
        @media(min-width: 375px) {
            padding    : 2.5rem 1rem 1rem;
        }
        @media(min-width: 414px) {
            max-width  : 400px;
            min-width  : 400px;
            padding    : 2.5rem 1.5rem 1rem;
        }
        @media(min-width: 780px) {
            max-width  : 500px;
            min-width  : 500px;
            padding    : 2.5rem 4rem 1rem;
        }
        h1{
            font-size: 33px;
            line-height: 33px;

            a{
                color: #009CDE;
                font-size: 33px;
            }
        }
        .logo{
            width: 110px;
            height: 110px;
            margin: 2rem auto
        }
        .divider {
            margin: 2rem 0
        }
        p{
            font-size: 14px;
            margin-bottom: 1rem!important;
            &.nowrap {
                a {
                    white-space: nowrap;
                }
            }
        }
    }
}
