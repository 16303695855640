/* Date Picker Default Styles */
.ui-datepicker {
    margin-top: 16px;
    background: $primary-purewhite;
    padding: 16px;
    border: $border;
    border-radius: 8px;
    box-shadow: $box-shadow;

    table {
        font-size: 13px;
        margin: 0;
    }
    .ui-datepicker-header {
        position: relative;
        border: none;
        color: $primary-black;
        padding-bottom: 16px;
        border-bottom: 1px solid $primary-grey;
        margin-bottom: 16px;
        background: 0;

        .ui-datepicker-title {
            font-size: $font-S;
            vertical-align: middle;
        }
        .ui-datepicker-prev,
        .ui-datepicker-next {
            @include position(absolute, n 0 n n);
            float: right;
            background-size: contain;
            width: 16px;
            height: 16px;

            span {
                display: none;
            }
        }
        .ui-datepicker-prev {
            background: url("../images/circle_arrow_left.svg");
            right: 28px;
        }
        .ui-datepicker-next {
            background: url("../images/circle_arrow_right.svg");
        }
    }
    th,
    td {
        text-align: center;
        border: none;
        font-size: $font-XS;
    }
    th {
        padding: 0 0 8px 0;
        color: $primary-lightgrey;
    }
    td {
        text-align: center;
        padding: 4px 8px;
        margin: 0;
        border-radius: 100%;

        a {
            color: $primary-black !important;
            font-weight: normal !important;
            border: 0 !important;
            background: 0 !important;
        }
        &:hover,
        &.ui-datepicker-current-day {
            background: $primary-blue;
            color: $primary-purewhite;

            a {
                background: 0;
                color: $primary-purewhite !important;
            }
        }

        &.ui-state-disabled,
        &.ui-state-disabled .ui-state-default {
            color: $primary-lightgrey !important;
            background: transparent !important;
            line-height: $font-line-height-regular;
            opacity: 1;

            span{
                border: 0 !important;
                color: $primary-lightgrey !important;
            }
        }
    }
}
