@function split-value($string, $separator) {
	$split-arr: ();
	$string: inspect($string);
	$index : str-index($string, $separator);
	@while $index != null {
		$item: str-slice($string, 1, $index - 1);
		$split-arr: append($split-arr, $item);
		$string: str-slice($string, $index + 1);
		$index : str-index($string, $separator);
	}
	$split-arr: append($split-arr, $string);

	@return $split-arr;
}

@mixin margin($unit, $direction: null) {
	@if ($direction == 't') {
		margin-top: $unit;
	} @else if ($direction == 'l') {
		margin-left: $unit;
	} @else if ($direction == 'r') {
		margin-right: $unit;
	} @else if ($direction == 'b') {
		margin-bottom: $unit;
	} @else {
		margin: $unit;
	}
}

@mixin padding($unit, $direction: null) {
	@if ($direction == 't') {
		padding-top: $unit;
	} @else if ($direction == 'l') {
		padding-left: $unit;
	} @else if ($direction == 'r') {
		padding-right: $unit;
	} @else if ($direction == 'b') {
		padding-bottom: $unit;
	} @else {
		padding: $unit;
	}
}

@mixin flex($content: null, $position: null, $wrap: null) {
	display: flex;
	@if ($content and $position) {
		@if ($content == 'both') {
			$value: split-value($position, '-');
			align-items: nth($value, 1);
			justify-content: nth($value, 2);
		} @else if ($content == 'align') {
			align-items: $position;
		} @else if ($content == 'justify') {
			justify-content: $position;
		}
	}
	@if ($wrap) {
		flex-wrap: $wrap;
	}
}

@mixin font($content: null, $data: null) {
	$value: split-value($data, ' ');
	@if ($content == 'all') {
		font-family: nth($data, 1);
		font-size: nth($value, 2);
		font-weight: nth($value, 3);
	} @else if ($content == 'family_size') {
		font-family: nth($data, 1);
		font-size: nth($data, 2);
	} @else if ($content == 'family_weight') {
		font-family: nth($data, 1);
		font-weight: nth($data, 2);
	} @else if ($content == 'size_weight') {
		font-size: nth($data, 1);
		font-weight: nth($data, 2);
	} @else if ($content == 'family') {
		font-family: $data;
	} @else if ($content == 'size') {
		font-size: $data;
	} @else if ($content == 'weight') {
		font-weight: $data;
	}
}

@mixin position($position, $directions: null) {
	position: $position;
	@if ($directions != null) {
		@if ($directions == 0) {
			top: $directions;
			right: $directions;
			bottom: $directions;
			left: $directions;
		} @else {
			$value: split-value($directions, ' ');
			$length: length($value);
			@if ($length == 1) {
				top: nth($value, 1);
				right: nth($value, 1);
				bottom: nth($value, 1);
				left: nth($value, 1);
			} @else if ($length == 2) {
				@if (nth($value, 1) != 'n') {
					top: nth($value, 1);
					bottom: nth($value, 1);
				}
				@if (nth($value, 2) != 'n') {
					right: nth($value, 2);
					left: nth($value, 2);
				}
			} @else if ($length == 3) {
				@if (nth($value, 1) != 'n') {
					top: nth($value, 1);
				}
				@if (nth($value, 2) != 'n') {
					right: nth($value, 2);
					left: nth($value, 2);
				}
				@if (nth($value, 3) != 'n') {
					bottom: nth($value, 3);
				}
			} @else if ($length == 4) {
				@if (nth($value, 1) != 'n') {
					top: nth($value, 1);
				}
				@if (nth($value, 2) != 'n') {
					right: nth($value, 2);
				}
				@if (nth($value, 3) != 'n') {
					bottom: nth($value, 3);
				}
				@if (nth($value, 4) != 'n') {
					left: nth($value, 4);
				}
			}
		}
	}
}

@mixin buttonColor($color) {
	@if ($color == blue) {
		background: $primary-bg;
		background: -webkit-linear-gradient(#42a1ec, #0070c9);
		background: -o-linear-gradient(#42a1ec, #0070c9);
		background: -moz-linear-gradient(#42a1ec, #0070c9);
		background: linear-gradient(#42a1ec, #0070c9);
	} @else if ($color == green) {
		background: $success-bg;
		background: -webkit-linear-gradient(#67c26d, #349d3b);
		background: -o-linear-gradient(#67c26d, #349d3b);
		background: -moz-linear-gradient(#67c26d, #349d3b);
		background: linear-gradient(#67c26d, #349d3b);
	} @else if ($color == gray) {
		background: $secondary-bg;
		background: -webkit-linear-gradient(#fff, #d7d7d7);
		background: -o-linear-gradient(#fff, #d7d7d7);
		background: -moz-linear-gradient(#fff, #d7d7d7);
		background: linear-gradient(#fff, #d7d7d7);
	} @else if ($color == red) {
		background: $error-bg;
		background: -webkit-linear-gradient(#e6553a, #cc1f00);
		background: -o-linear-gradient(#e6553a, #cc1f00);
		background: -moz-linear-gradient(#e6553a, #cc1f00);
		background: linear-gradient(#e6553a, #cc1f00);
	}
}

@mixin buttonSize($size) {
	@if ($size == medium) {
		height: 30px;
		max-width: 100px;
		width: 100%;
		border: 0;
		border-radius: 4px;
		color: $primary-color;
	}
}

@mixin appearance($value) {
	appearance: $value;
	-webkit-appearance: $value;
	-moz-appearance: $value;
}
