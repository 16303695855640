@import "../settings/variables";

.swal-overlay {
    /* IE8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    /* IE8 */
    background: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 10000;
}
.swal-modal {
    background: $primary-purewhite;
    width: 100%;
    max-width: 512px;
    border-radius: 8px;
    box-shadow: $modal-shadow;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;

    @include breakpointByScreenType(mobile) {
        padding: 32px;
        width: calc(100% - 40px); 
    }
    @include breakpointByScreenType(tablet) { padding: 48px 64px; }
    .swal-title {
        font-size: $font-XL;
        line-height: $font-XL;
        color: $primary-black;
        margin: 0;
        padding: 0;
        font-weight: normal;
    }
    .swal-icon {
        margin: 40px 0;

        img {
            width: 72px;
            height: auto;
        }
        &:first-child { margin: 0 auto; }
    }
    .swal-text {
        width: 100%;
        max-width: unset;
        padding: 0;
        text-align: center;
        font-size: $font-S;
        line-height: $font-M;

        span { color: $primary-blue; }
    }
    .swal-footer {
        padding: 0;
        margin-top: 40px; 

        .swal-button-container {
            margin: 0;
            width: 100%;
            padding-bottom: 16px;
            box-sizing: border-box;

            &:only-child, &:last-child { padding-bottom: 0; }
            button {
                font-family: $font-family;
                letter-spacing: $font-letter-spacing;
                border-radius: 4px;
                font-size: 17px;
                background: 0;
                border: 0;
                cursor: pointer;
                position: relative;
                width: 100%;
                color: $primary-purewhite;
                padding: 2px 12px;
                height: 40px;
                font-weight: 400;

                &:hover { filter: brightness(95%); }
                &:active { filter: brightness(90%); }
                &:focus { box-shadow: none; }

                &:disabled {
                    filter: brightness(105%);
                    cursor: progress;

                    &:hover { filter: 0; }
                    &:active { filter: 0; }
                }
                &.swal-button--cancel {
                    color: $primary-blue;
                    border: $primary-blue 1px solid;
                    background: $primary-purewhite;
                }
                &.swal-button--confirm {
                    // border: $button-border-blue 1px solid;
                    background: #0099dd;
                }
            }
        }
    }
    &.style_2 {
        max-width: 500px;

        .swal {
            &-title { font-size: $font-L; }
            &-icon { display: none; }
            &-content {
                border-bottom: $border;
                padding: 0 0 30px;

                p {
                    border-bottom: $border;
                    padding: 0 0 30px;
                }
                i {
                    display: block;
                    padding-top: 30px;
                    font-style: italic;
                    color: $primary-black;
                }
                span {
                    display: block;
                    margin-top: 30px;
                    font-size: $font-XS;
                    text-align: right;
                    color: $primary-black;
                }
            }
            &-footer {
                max-width: 250px;
                margin: 30px auto 0;
            }
            &-button {
                &--confirm {
                    background: $secondary-green !important;
                    text-transform: uppercase;
                }
                &--cancel {
                    background: $secondary-red !important;
                    text-transform: uppercase;
                    color: $primary-purewhite !important;
                    border: 0 !important;
                }
            }
        }
    }
    input {
        max-width: 250px;
        margin: auto
    }
}

/* Animations */
@-webkit-keyframes showSweetAlert {
    0% {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
    } 45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    } 80% {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    } 100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes showSweetAlert {
    0% {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
    } 45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    } 80% {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    } 100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@-webkit-keyframes hideSweetAlert {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    } 100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
}

@keyframes hideSweetAlert {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    } 100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
}

.showSweetAlert[data-animation=pop] {
    -webkit-animation: showSweetAlert 0.3s;
    animation: showSweetAlert 0.3s;
}

.hideSweetAlert[data-animation=pop] {
    -webkit-animation: hideSweetAlert 0.2s;
    animation: hideSweetAlert 0.2s;
}
