@mixin MQ($pixels, $orientation: null) {
	@if ($orientation) {
		@media only screen and (max-width: $pixels) and (orientation: $orientation) { @content; }
	} @else {
		@media only screen and (max-width: $pixels) { @content; } 
	}
}

@mixin breakpointByScreenType($class, $orientation: null) {
	$query: "" !default;

	@if ($class == mobile) {
		@media only screen and (max-width: 767px) { @content; }
	}@else if ($class == tablet) {
		@media only screen and (min-width: 768px) { @content; }
	}@else if ($class == medium) {
		@media only screen and (min-width: 1024px) { @content; }
	}@else if $class == large {
		@media only screen and (min-width: 1200px) { @content; }
	}@else {
		@warn "Breakpoint mixin supports: mobile, tablet, small, large";
	}
}

@mixin breakpointBySize($min: 0, $max: 0, $orientation: null){
	$query: "" !default;

	@if $min != 0 and $max != 0 { 
		$query: "(min-width: #{$min}) and (max-width: #{$max})"; 
	}@else if $min != 0 and $max == 0 { 
		$query: "(min-width: #{$min})"; 
	}@else if $min == 0 and $max != 0 { 
		$query: "(max-width: #{$max})"; 
	}@else{
		@warn "$min and/or $max must be numbers";
	}

	@if $orientation{
		$query: "#{$query} and (orientation: #{$orientation})";
	}

	@media only screen and #{$query} { @content; }
}