@import "./settings/variables";
@import "./settings/mixins";
@import "./settings/responsive";
@import "./settings/animation";
@import "./base/table";
@import "./base/datepicker";
@import "./modules/achievement";
@import "./modules/image-editor";

.representative_terms {
    margin-bottom: 40px!important;
}
.business-form-modal,
.individual-form-modal {
    .input-group {
        margin: 16px 0;
    }
    .footer {
        margin-top: 16px;
        .terms-and-privacy {
            margin-top: 16px;
            width: auto!important;
            font-size: 11px!important;
        }
    }
}
.progress {
    margin-bottom: 1.5rem;
    .steps {
        text-align: center;
        margin-bottom: 0.75rem;
        font-weight: $font-weight-bold;
        color: $primary-lightgrey;
        .active {
            color: $primary-black;
        }
    }
    .MuiLinearProgress-root {
        background-color: $primary-lightgrey;
    }
}