.faqs_list{
	li {
	  	list-style-type: none;
	 }
	ul.dropdown-content {
	  position: block;
	  display: none;

	  li {
	  	list-style-type: none;
	  }
	}
}
