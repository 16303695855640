@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.shake{ animation: shake 0.5s linear; }

@keyframes shake {
    from, to {
      transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translate3d(-8px, 0, 0);
    }
    20%, 40%, 60%, 80% {
      transform: translate3d(8px, 0, 0);
    }
}
