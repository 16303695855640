.icon{
    position: relative;
    input{ padding-left: 40px; }
    &:before, .input-icon {
        content: '';
        background-size: contain;
        font-family: fa-solid;
        color: $primary-lightgrey;
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        width: 14px;
        height: 14px;
    }
    &.right {
        &:before, .input-icon {
            left: unset;
            right: 12px;
        }
        input{
            padding-left: 12px; 
            padding-right: 40px; 
        }
    }
    &.icon-jpt:before{ background-image: url('../images/jpt-logo.svg'); }
    &.icon-email:before{ background-image: url('../images/email.svg'); }
    &.icon-card:before{ background-image: url('../images/card-number.svg'); }
    &.icon-amount:before{ background-image: url('../images/amount.svg'); }
    &.icon-user:before{ background-image: url('../images/user.svg'); }
    &.icon-password:before{ background-image: url('../images/password.svg'); }
    &.icon-date-from:before{ background-image: url('../images/date-from.svg'); }
    &.icon-date-to:before{ background-image: url('../images/date-to.svg'); }
    &.icon-search:before{ background-image: url('../images/search.svg'); }
    &.icon-link:before{ content: '\f0c1'; }
    &.icon-check:before{ background-image: url('../images/success.svg'); }
    &.icon-error:before{ background-image: url('../images/error.svg'); }
    &.icon-facebook:before{
        font-family: fa-brand;
        content: '\f082';
        color: $social-fb;
    }
    &.icon-twitter:before{ 
        font-family: fa-brand;
        content: '\e61b';
        color: $social-twitter;
    }
    &.icon-instagram:before{ background-image: url('../images/instagram.svg'); }
    &.icon-linkedin:before{ 
        font-family: fa-brand;
        content: '\f0e1';
        color: $social-linkedin;
    }
    &.icon-menu:before{ content: '\f0c9'; }
    
    &.icon-zoom-in:before{ content: '\f00e'; }
    &.icon-zoom-out:before{ content: '\f010'; }
    &.icon-rotate-left:before{ content: '\f2ea'; }
    &.icon-rotate-right:before{ content: '\f2f9'; }
    &.icon-undo:before{ content: '\f0e2'; }
}