body .footer {
	@include position(relative);
	width: 100%;
	border-top: $border;
	box-sizing: border-box;
	background-color: $primary-purewhite;

    .container{
        @include breakpointBySize(0, $break-medium-max){ padding: 16px 20px; }
		@include breakpointByScreenType(large){ padding: 16px 0; }
		.links{
			@include breakpointByScreenType(tablet){@include flex(justify, space-between); }
			.link-set{
				@include breakpointByScreenType(tablet){ flex: 1; }
				div{
					line-height: 32px;
					font-size: $font-S;
					color: $primary-black;
				}
				ul{ 
					padding: 0; 

					@include breakpointByScreenType(mobile){
						max-height: 0;
						overflow: hidden;
						transition: all 0.5s ease-in; 

						&.show{ max-height: 400px; }
					}
					li {
						line-height: 24px;
						list-style: none;
			
						a{
							font-size: $font-XS;
							color: $primary-grey;
							text-decoration: none;
							white-space: nowrap;
							
							&:hover{ color: $primary-blue; }
							&[href="#"]{
								color: $primary-lightgrey;
								&:hover{
									color: $primary-lightgrey;
									cursor: default;
								}
							}
						}
					}
				}
			}
		}
		p{
			font-size: $font-XS;
			// font-weight: 600;
		}
	}
}