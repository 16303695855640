@import 'settings/mixins';
@import 'settings/variables';
@import './settings/responsive';

@import 'modules/stepper';
@import 'modules/achievement';
@import 'modules/image-editor';
@import 'modules/e-signature';

@keyframes progress-bar-stripes { 0% { background-position-x: -32px; } }

.onboarding {
    .footer {
        margin-top: 40px;
        @media(min-width: 768px) {
            margin-top: 0;
        }
        border: 0;
    }
    .modal{
        h3{
            margin-top: 24px;
        }
    }
    .verification-form > .input-group{ margin-bottom: 24px; }
    .action-box.success{
        p{
            width: unset;
            margin: 0 0 40px;
        }
    }
}
.progress_modal{
    width: 100vw;
    min-width: 100vw;
    @media screen and (min-width: 0) and (max-width: 360) {
        width: 100%;        
    }
    @media screen and (min-width: 500px) {
        min-width: 500px;
    }
    padding: 40px !important;
    text-align: center;
    h3{ margin-bottom: 16px; }
    .content {
        height: 100%;
        .progress {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &-bar{
                width: 100%;
                height: 16px;
                border: $border;
                border-radius: 4px;
                margin-bottom: 16px;
            
                &__indicator{
                    width: 100%;
                    height: inherit;
                    background-color: #0099dd;
                    background-image: linear-gradient(45deg, rgba(white, 0.15) 25%, transparent 25%, transparent 50%, rgba(white, 0.15) 50%, rgba(white, 0.15) 75%, transparent 75%, transparent);
                    background-size: 32px 32px;
                    animation: progress-bar-stripes 1s linear infinite;
                }
            }
        }
    }
}
