@import "../settings/variables";
@import "../settings/responsive";
@import "../settings/mixins";
header.header{
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 56px;
    background-color: $primary-purewhite;
    border-bottom: $border;
	box-sizing: border-box;
    
    .container{
        height: 100%;
        padding: 16px 20px;
        
        @include breakpointByScreenType(medium){
            padding: 0 20px;
            @include flex(justify, space-between);
            align-items: center;
        }
        @include breakpointByScreenType(large){ padding: 16px 0; }
        .logo{
            display: flex;
            height: 24px;
            max-height: 24px;
            object-fit: contain;

            @include breakpointBySize(0, $break-tablet-max){
                position: relative;
                float: left;
            }
        }
        .menu{
            width: 24px;
            height: 24px;
            
            &:before{
                width: 24px;
                height: 24px;
                font-size: 24px;
                line-height: 24px;
                left: 0;
            }
 
            @include breakpointBySize(0, $break-tablet-max){
                position: relative;
                float: right;
            }
            @include breakpointByScreenType(medium){ display: none; }
        }
        nav{
            @include breakpointBySize(0, $break-tablet-max){
                position: absolute;
                right: 0;
                top: 55px;
                background: $primary-purewhite;
                border: $border;
                border-top: none;
                max-height: 0;
                overflow: hidden;
                
                transition: all 0.5s ease-in; 

                &.show{
                    border-top: $border;
                    max-height: 100px; //set to 350 when the other links are added
                }
            }
            @include breakpointByScreenType(medium){ @include flex(align, center); }
            div{
                box-sizing: border-box;
                margin: 16px 24px;

                @include breakpointByScreenType(medium){
                    margin: 0 12px;
                    border-bottom: transparent;
                    border-top: transparent;
                    transition: all 0.25s ease-out;

                    &.active, &:hover{
                        padding: 4px 0; 
                        border-bottom: 2px solid $primary-blue;
                        border-top: 2px solid transparent;
                    }
                    &:last-of-type{ margin-right: 0; }
                }
                a{
                    font-size: $font-S;
                    text-decoration: none;
                    // color: $primary-black; UNCOMMENT WHEN LINKS ARE ADDED
                }
            }
            .divider{
                margin: 16px 0;
                display: none; //REMOVE WHEN LINKS ARE ADDED

                @include breakpointByScreenType(medium){
                    display: block; //REMOVE WHEN LINKS ARE ADDED
                    position: static;
                    margin: 0 12px;
                    width: 1px;
                    height: 24px;
                    border-left: 1px $primary-black solid;
                }
                // &~div>a{ color: $primary-blue; } UNCOMMENT WHEN LINKS ARE ADDED
            }
        }
    }
    &~main{
        position: relative;
        min-height: calc(100vh - 56px);
        margin-top: 56px;
        box-sizing: border-box;
    }
}